<template>
    <v-card>
        <form>
            <div class="grid grid-cols-2 gap-4 <md:grid-cols-1">
                <v-form-group :label="$t('label.firstname')" class="mb-4" :v="v$.current_user.firstname">
                    <v-form-input v-model="current_user.firstname" />
                </v-form-group>

                <v-form-group :label="$t('label.lastname')" class="mb-4" :v="v$.current_user.lastname">
                    <v-form-input v-model.trim="current_user.lastname" />
                </v-form-group>
            </div>

            <div class="grid grid-cols-2 gap-4 <md:grid-cols-1">
                <v-form-group :label="$t('label.mobile')" class="mb-4" :v="v$.current_user.mobile">
                    <v-form-input v-model.trim="current_user.mobile" type="tel" />
                </v-form-group>

                <v-form-group :label="$t('label.email')" class="mb-4" :v="v$.current_user.email">
                    <v-form-input v-model.trim="current_user.email" type="email" autocomplete="username" />
                </v-form-group>
            </div>

            <div class="grid grid-cols-2 gap-4 <md:grid-cols-1">
                <v-form-group :label="$t('label.password')" class="mb-4" :v="v$.new_password.password">
                    <v-form-input v-model.trim="new_password.password" type="password" autocomplete="new-password" />
                </v-form-group>

                <v-form-group :label="$t('label.password_confirmation')" class="mb-4" :v="v$.new_password.password_confirmation">
                    <v-form-input v-model.trim="new_password.password_confirmation" type="password" autocomplete="new-password" />
                </v-form-group>
            </div>

            <footer class="flex justify-end <md:flex-col">
                <v-button variant="danger" icon="power" class="mr-2 <md:w-full <md:mr-0 <md:mb-2" @click="handleLogout">
                    {{ $t('action.logout') }}
                </v-button>

                <v-button variant="primary" class="<md:w-full" :loading="loading" @click="handleSubmit">
                    {{ $t('action.save') }}
                </v-button>
            </footer>
        </form>
    </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import { useVuelidate } from '@vuelidate/core';
import { required, email, minLength, sameAs } from '@vuelidate/validators';
import { phone } from '@/assets/javascript/vuelidate.js';

export default {
    setup () {
        return {
            v$: useVuelidate()
        }
    },

    data: () => ({
        loading: false,
        new_password: {
            password: null,
            password_confirmation: null
        }
    }),

    computed: {
        ...mapGetters('auth', [
            'current_user'
        ]),
    },

    validations () {
        return {
            current_user: {
                firstname: { required },
                lastname: { required },
                mobile: { required, phone },
                email: { required, email }
            },

            new_password: {
                password: { minLength: minLength(8) },
                password_confirmation: { sameAsPassword: sameAs(this.new_password.password) }
            }
        }
    },

    methods: {
        ...mapActions('auth', [
            'updateCurrentUser',
            'logout'
		]),

        handleSubmit () {
            this.v$.$touch();

            if (this.v$.$error) return;

            this.v$.$reset();

            this.loading = true;

            if (this.new_password.password) this.current_user.password = this.new_password.password;

            this.updateCurrentUser(this.current_user).finally(() => {
                this.loading = false;
            });
        },

        handleLogout () {
            this.logout().then(() => {
                this.$redirect('home');
            })
        }
    }
};
</script>
