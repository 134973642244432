<template>
    <backoffice-layout :title="$t('my_profile')">
        <profile-form />
    </backoffice-layout>
</template>

<script>
import profileForm from '@/patterns/profileForm';

export default {
    components: {
        profileForm
    }
}
</script>
